import React from 'react';
import Title from './componant/Title';
import Hrs from './componant/Hrs';
import ProjectsSection from './componant/ProjectsSection';
import ReInvent from './componant/ReInvent';
import WhatWeCanDo from './componant/WhatWeCanDo';
import ProfessionalsSection from './componant/ProfessionalsSection';
import Services from './Services';

const Home = () => {
  return (
    <>
    <Title/>
  <Hrs/>
  <ProjectsSection/>
  <ReInvent/>

  <WhatWeCanDo/>
   <Services/>
    </>
  );
};

export default Home;