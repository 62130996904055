import React from 'react';

const ReInvent = () => {
  return (
    <section className=" bg-gray-900">
      <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div className="sm:text-lg text-gray-400">
          <h2 className="mb-4 text-4xl  font-extrabold text-white">We didn't reinvent the wheel</h2>
          <p className="mb-4 font-bold">We are strategists, designers and developers. Innovators and problem solvers. Small enough to be simple and quick, but big enough to deliver the scope you want at the pace you need. </p>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-8">
          <img className="w-full rounded-lg" src="./images/wheel.jpg" />
          <img className="mt-4 w-full lg:mt-10 rounded-lg" src="./images/wheel2.jpg" alt="office content 2" />
        </div>
      </div>
    </section>
  );
};

export default ReInvent;