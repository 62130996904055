import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../Home";
import About from "../About";
import Services from "../Services";
import Contact from "../Conatct";
import PartnersPage from "../PartnersPage";


const Router = createBrowserRouter ([
    {
        path:"/" ,
        element: <App/>,
        children:[
            {
                path:"/",
                element: <Home/>
            }, 
            {
                path:"/about",
                element:<About/>
            },
            {
                path:"/services",
                element:<Services/>
            },
            {
                path:"/contact",
                element:<Contact/>
            },{
                path:"/Partners", 
                element:<PartnersPage/>
            }
        ]
    }
]);
export default Router ; 