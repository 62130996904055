// src/components/ProjectCard.js

import React from 'react';

const ProjectCard = ({ project }) => {
  return (
    <a className="bg-white shadow-lg rounded-lg border p-3 overflow-hidden cursor-pointer hover:shadow-xl transition-shadow duration-300 mb-4s mb-4"
        href={project.priUrl}
        target="_blank"

    >
              <h3 className="text-xl font-medium mb-2 text-center">{project.title}</h3>

      <img
        className="w-full h-56 object-cover object-center"
        src={project.imageUrl}
        alt={project.title}
      />
      <div className="p-4">
        <p className="text-gray-600">{project.description}</p>
        
      </div>
    </a>
  );
};

export default ProjectCard;