import React from 'react';
import { FaTwitter, FaLinkedin, FaGithub } from 'react-icons/fa';

const PartnersPage = () => {
  const partners = [
    {
      name: 'Al-aliaa Group',
      logo: "https://al-aliaa.iq/logo.png",
      website: 'https://al-aliaa.iq/',
      description: 'Al-Aliaa Company for Travel, Tourism, and Organization of International Conferences is a prominent company based in Iraq. It specializes in various services related to travel, tourism, and the organization of international conferences. The company has gained recognition for its expertise in managing high-profile events, including the Baghdad International water\'s Conference (IWC).      ',
    },
    {
      name: 'Inshaa-ind',
      logo: "https://img.inshaa-ind.com/logo-dark.png",
      website: 'https://inshaa-ind.com/',
      description: 'INSHAA is a Group of private companies providing Construction Services for Power Plants, Refineries and oil plants, Gas & Oil surface facilities and gathering stations, Cement Plants, Wheat Mills, Water and Water Treatment Plants, and various industrial plants. ',
    },
    {
      name: 'AZM LLC',
      logo: "https://azm.inshaa-ind.com/AZM/assets/images/Logo.png",
      website: 'https://azm.inshaa-ind.com/AZM/',
      description: 'AZM LLC is a private company providing Construction Services for Power Plants, Refineries and oil plants, Gas & Oil surface facilities and gathering stations',
    },
  ];

  return (
    <div className="container mx-auto py-8 m-2">
      <h1 className="text-2xl font-bold mb-4 p-3">Our Partners</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 ">
        {partners.map((partner, index) => (
          <div
            key={index}
            className="bg-white shadow-md rounded-md p-4 flex flex-col items-center relative p-2"
          >
            <img
              src={partner.logo}
              alt={partner.name}
              className="m-4  h-40"
            />
            <h2 className="text-lg font-semibold mb-2">{partner.name}</h2>
            <p className="text-left m-2">{partner.description}</p>
            <br/>
            <a
              href={partner.website}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-600  absolute  bottom-0 m-2"
            >
              Visit Website
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartnersPage;