import { Outlet, useLocation } from 'react-router-dom';
import './App.css';
import Footer from './componant/Footer';
import Nav from './componant/Nav';
import { useEffect } from 'react';

function App() {
  const location = useLocation();
  useEffect( () => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
   <>
   <Nav/>
   <Outlet/>
   <Footer/>
   </>
  );
}

export default App;
