import React from 'react';
import { FaDesktop, FaMobile, FaCode, FaRobot } from 'react-icons/fa';
import { GiBrain } from 'react-icons/gi';
import { IoAnalyticsSharp } from 'react-icons/io5';

const servicesData = [
  {
    title: 'Web Development',
    description: 'We create beautiful and responsive websites using the latest technologies.',
    icon: <FaDesktop className="w-8 h-8 text-blue-500" />,
  },
  {
    title: 'Mobile App Development',
    description: 'We build native and cross-platform mobile applications for iOS and Android.',
    icon: <FaMobile className="w-8 h-8 text-orange-500" />,
  },
  {
    title: 'UI/UX Design',
    description: 'We design stunning user interfaces that enhance user experiences.',
    icon: <GiBrain className="w-8 h-8 text-purple-500" />,
  },
  {
    title: 'Backend Development',
    description: 'We develop robust and scalable backend systems for your applications.',
    icon: <FaCode className="w-8 h-8 text-green-500" />,
  },
  {
    title: 'Data Analytics',
    description: 'We provide data analysis services to help you gain insights and make informed decisions.',
    icon: <IoAnalyticsSharp className="w-8 h-8 text-red-500" />,
  },
  {
    title: 'Build AI Chatbots',
    description: 'We specialize in building AI-powered chatbots that enhance customer interactions.',
    icon: <FaRobot className="w-8 h-8 text-yellow-500" />,
  },
];

const Services = () => {
  return (
    <div className="bg-gray-100 py-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold mb-8 text-gray-800">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {servicesData.map((service, index) => (
            <div key={index} className="bg-white rounded-lg p-6 shadow-md">
              <div className="flex items-center justify-center mb-4">
                {service.icon}
              </div>
              <h3 className="text-xl font-bold mb-2">{service.title}</h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;