import React from 'react';

const Hrs = () => {
  return (
  <div className="flex flex-row justify-center">
    <div className="text-center">
      <img src="./images/heading_border_left.png" />
    </div>
    <div className="text-center">
      <img src="./images/heading_border_right.png" />
    </div>
  </div>
  );
};

export default Hrs;