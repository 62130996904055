import React from 'react';

const About = () => {
  return (
    <div class="bg-gray-100">
    <div class="container mx-auto py-8">
        <div class="grid grid-cols-4 sm:grid-cols-12 gap-6 px-4">
            <div class="col-span-4 sm:col-span-3">
                <div class="bg-white shadow rounded-lg p-6">
                    <div class="flex flex-col items-center">
                    <h1 class="text-2xl font-bold m-2">CEO and Founder </h1>


                        <img src="./images/GhaythMooustapha.jpg" class="w-32 h-32 bg-gray-300 rounded-full mb-4 shrink-0">

                        </img>
                        <h1 class="text-xl font-bold">Ghayth Moustapha </h1>
                        <p class="text-gray-700">Software Engineer </p>
                       
                    </div>
                    <hr class="my-6 border-t border-gray-300"/>
                    <div class="flex flex-col"> 
                     <ul>
                         <li class="mb-2 "> <span className='font-bold' > Master’s in Big Data Science<br/> </span>
                          Higher Institute for Applied Sciences and Technology (HIAST)</li> 
                          <li class="mb-2 ">
                          <span className='font-bold' > 
                            Master’s in Web Science<br/>
                            </span>
                             Syrian Virtual University (SVU)</li> 
                             
                             <li class="mb-2">
                             <span className='font-bold' >
                                Bachelor's  in IT Engineering<br/> </span>
                                Syrian Virtual University (SVU) </li>
                                
                                 <li class="mb-2">
                                 <span className='font-bold' >
                                    Bachelor's  in Media and Marketing <br/> 
                                    </span>Damascus University </li> </ul> </div>
                    <hr class="my-6 border-t border-gray-300"/>
                    
                </div>
            </div>
            <div class="col-span-4 sm:col-span-9">
                <div class="bg-white shadow rounded-lg p-6">
                    <h2 class="text-2xl font-bold mb-4">About Eliejah </h2>
                    <p class="text-gray-900 text-xl m-2">ELiejah  is a leading company specializing in harnessing the power of artificial intelligence to drive long-term value and economic growth in the technology industry. 
                    
                    <br/> Our core focus lies in markets where AI, innovation, and capital intersect, allowing us to create groundbreaking solutions that revolutionize the way businesses operate.</p>

<p class="text-gray-900 text-xl m-2">With a profound understanding of AI technologies and their immense potential, we continuously push the boundaries of what is achievable. Our team of experts is dedicated to developing cutting-edge AI solutions that tackle complex challenges, optimize processes, and enhance decision-making across a wide range of industries.</p>

<p class="text-gray-900 text-xl m-2 ">At ELiejah AI, we firmly believe that the integration of AI into businesses can bring about transformative outcomes. By leveraging advanced algorithms, machine learning, and data analytics, we empower organizations to make data-driven decisions, streamline operations, and unearth valuable insights that drive efficiency and growth.</p>

<p class="text-gray-900 text-xl m-2">Collaboration and innovation are the cornerstones of our approach. We work closely with our clients to gain a deep understanding of their unique needs and tailor AI solutions that align with their specific business objectives.

<br/> Whether it involves developing intelligent automation systems, predictive analytics models, or natural language processing applications, we are committed to delivering tangible results that have a lasting impact on our clients' success.</p>

<p class="text-gray-900 text-xl m-2">Furthermore, we place great importance on responsible and ethical AI deployment. Transparency, fairness, and accountability are prioritized in our AI systems, ensuring they are free from bias and adhere to the highest standards of data privacy and security.</p>

             
                    
                       
                    </div>
                </div>
            </div>
        </div>
    </div>

  );
};

export default About;