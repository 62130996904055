import React from 'react';

const Title = () => {
  return (
    <div className="flex items-center justify-center  p-10">
    <div className="container">
      <div className="text-center">
        <h2 className="text-4xl font-bold text-gray-900 mb-4">
          <span className="text-indigo-800">Eliejah </span> |  IT Like Pro
        </h2>
        <p className="text-lg text-slate-900 font-bold ">GET ALL YOUR TECHNOLOGY NEEDS </p>
      </div>
    </div>
  </div>
  );
};

export default Title;