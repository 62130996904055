import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const isCurrentLocation = (pathname) => {
    return location.pathname === pathname;
    
  };
  useEffect(()=>{
    setIsMenuOpen(false);

  },[location])

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
<nav className="bg-slate-800 sticky top-0 z-50 left-0 w-full">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img

                className="h-12 w-15"
                src="./images/logo.png"
                alt="Logo"
              />
            </div>  
            <h1 className="text-white font-bold text-4xl tracking-wider m-2"> Eliejah</h1>

            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <Link to="/" 
                className={`text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium ${isCurrentLocation('/') ? 'bg-gray-700' : ''}`}>
                  Home
                </Link>
                <Link to="/about"  
                className={`text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium ${isCurrentLocation('/about') ? 'bg-gray-700' : ''}`}>
                About
                  </Link>
                  <Link to="/services"
                className={`text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium ${isCurrentLocation('/services') ? 'bg-gray-700' : ''}`}>

                Services
                </Link>
                <Link to="/Partners"
                className={`text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium ${isCurrentLocation('/Partners') ? 'bg-gray-700' : ''}`}>
                Partners
                </Link>
                <Link to="/contact"
                className={`text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium ${isCurrentLocation('/contact') ? 'bg-gray-700' : ''}`}>
                Contact
                </Link>
              
              </div>
            </div>
          </div>

          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              type="button"
              className="bg-slate-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`${isMenuOpen ? 'hidden' : 'block'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`${isMenuOpen ? 'block' : 'hidden'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden`} id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <Link to="/" 
                className={`text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium ${isCurrentLocation('/') ? 'bg-gray-700' : ''}`}>
                  Home
                </Link>
                <Link to="/about"  
                className={`text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium ${isCurrentLocation('/about') ? 'bg-gray-700' : ''}`}>
                About
                  </Link>
                  <Link to="/services"
                className={`text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium ${isCurrentLocation('/services') ? 'bg-gray-700' : ''}`}>

                Services
                </Link>
                <Link to="/Partners"
                className={`text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium ${isCurrentLocation('/Partners') ? 'bg-gray-700' : ''}`}>
                Partners
                </Link>
                <Link to="/contact"
                className={`text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium ${isCurrentLocation('/contact') ? 'bg-gray-700' : ''}`}>
                Contact
                </Link>
               
        </div>
      </div>
    </nav>
  );
};

export default Nav;